@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.contact-info {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.contact-heading {
  font-family: 'Poppins', sans-serif;
  font-size: 3rem;
  background: linear-gradient(45deg, #a69907, #e9e61c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
}

.contact-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  position: relative;
  z-index: 1;
}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333;
}

.icon {
  margin-right: 1rem;
  color: #c3c31c;
}

a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #dfed88;
}

.yellow-path {
  pointer-events: none;
}