.projects-section {
    padding: 4rem 0;
    background-color: #fffbeb;
  }
  
  .projects-inner-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    color: #1a1a1a;
  }
  
  .projects-container {
    display: flex;
    overflow-x: auto;
    gap: 2rem;
    padding: 1rem;
    padding-bottom: 2rem;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .projects-container::-webkit-scrollbar {
    display: none;
  }
  
  .project-card {
    flex: 0 0 320px;
    background: #fff9e5;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    scroll-snap-align: start;
    margin-bottom: 1rem;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .project-card-content {
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .project-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 0.75rem;
  }
  
  .project-tech {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  .project-description {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    flex-grow: 1;
  }
  
  .project-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #ffd700;
    color: #1a1a1a;
    text-decoration: none;
    border-radius: 6px;
    font-weight: 500;
    transition: background-color 0.2s;
    align-self: flex-start;
  }
  
  .project-link:hover {
    background-color: #ffcd00;
  }

  /* ... Keep existing CSS ... */

.projects-wrapper {
    position: relative;
    margin: 0 -1rem;
    padding: 0 1rem;
  }
  
  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 215, 0, 0.9);
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 10;
  }
  
  .scroll-button:hover {
    background: rgba(255, 215, 0, 1);
    transform: translateY(-50%) scale(1.1);
  }
  
  .scroll-left {
    left: 0.5rem;
  }
  
  .scroll-right {
    right: 0.5rem;
  }
  
  .projects-container {
    scroll-behavior: smooth;
  }
  
  .scroll-indicator {
    text-align: center;
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
    animation: fadeInOut 2s infinite;
  }
  
  .scroll-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #ffd700;
    border-radius: 50%;
    opacity: 0.5;
  }
  
  .dot:nth-child(1) {
    animation: pulse 1.5s infinite;
  }
  
  .dot:nth-child(2) {
    animation: pulse 1.5s infinite 0.5s;
  }
  
  .dot:nth-child(3) {
    animation: pulse 1.5s infinite 1s;
  }
  
  @keyframes pulse {
    0%, 100% {
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.2);
    }
  }
  
  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
  }