/* General App Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Body and Section Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.section.active {
  opacity: 1;
}

/* Navbar Styles */
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.8);
  transition: background-color 0.3s ease;
}

.navbar-brand, .nav-link {
  color: #fff !important;
}

.navbar-brand .highlight {
  color: #FFD700;
  font-weight: bold;
}

#skills {
  background-color: #f4eb9c;
  padding: 40px 20px;
}

.skill-showcase {
  max-width: 1200px;
  margin: 0 auto;
}

.skill-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.skill-item {
  background-color: #d2e4ef;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skill-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.skill-icon {
  font-size: 48px;
  margin-bottom: 15px;
  color: #7b762a;
}

.skill-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.skill-details {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.skill-bar {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.skill-progress {
  height: 100%;
  background-color: #7b762a;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .skill-grid {
    grid-template-columns: 1fr;
  }
}

.section-title {
  color: #120c01;
  margin-bottom: 40px;
  font-weight: bold;
}

/* Scroll Indicator Styles */
.scroll-indicator {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #007bff;
}

.projects-section {
  background-color: #fffbe6;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.projects-inner-container {
  background-color: #fff9db;
  border: 2px solid #ffd700;
  border-radius: 12px;
  padding: 2rem;
  max-width: 1000px;
  width: 100%;
}

.section-title {
  font-size: 2rem;
  color: #8b7500;
  margin-bottom: 2rem;
  text-align: center;
}

.projects-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.project-card {
  flex: 1;
  background-color: #fff5cc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-card-content {
  padding: 1.5rem;
}

.project-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #090909;
  margin-bottom: 0.5rem;
}

.project-tech {
  font-size: 0.9rem;
  color: #a38e00;
  margin-bottom: 0.5rem;
}

.project-description {
  font-size: 0.9rem;
  color: #120f01;
  margin-bottom: 1rem;
}

.project-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #ffd700;
  color: #5c4d00;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.project-link:hover {
  background-color: #ffed4a;
}

@media (max-width: 768px) {
  .projects-container {
    flex-direction: column;
  }
}

.download-buttons-container {
  display: flex;
}

.download-buttons-container > * + * {
  margin-left: 1rem;
}